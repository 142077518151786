import $ from 'jquery'
import { cookie } from '../utilities'

$('document').ready( function(){
  const $banner = $('.js-sticky-banner')
  const $button = $('.js-sticky-banner__close')

  const closed = cookie.get('sticky-banner') === 'closed'
  if( ! closed ){
    $banner.slideDown()
  }

  $button.click( function(){
    $banner.slideUp()
    cookie.set( 'sticky-banner', { value: 'closed', expires: cookie.expires(1) } )
  } )
})
